/** @jsx jsx */
import { navigate } from 'gatsby';
import { jsx } from 'theme-ui';
import { Button } from '../Button';
import { ArticleItem } from '../ArticleItem';

interface Props {
  data: any;
  basePath: string;
}
const ArticlePostList = ({ data, basePath }: Props) => {
  return (
    <div>
      <div>
        {data.map((article: any) => {
          return (
            <ArticleItem
              basePath={basePath}
              key={article.id}
              article={article}
            />
          );
        })}
      </div>
      <Button
        onClick={() => navigate(basePath + '/archive')}
        sx={{ display: 'block', margin: '0 auto' }}
      >
        Go to Archive
      </Button>
    </div>
  );
};

export default ArticlePostList;
