/** @jsx jsx */
import React from 'react';
import { Layout } from '../components/Layout';
import { Sidebar } from '../components/Sidebar';
import { jsx } from 'theme-ui';
import { FeaturedPosts } from '../components/FeaturedPosts';
import { ArticlePostList } from '../components/ArticlePostList';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { SEO } from '../components/SEO';
import { AboutAuthorWidget } from '../components/AboutAuthorWidget';

interface Props {
  pageContext: any;
}
const Index = ({ pageContext }: Props) => {
  const {
    BeforeFooter,
    BeforeArticlesList,
    BeforeFeaturedArticles,
  } = pageContext;
  return (
    <React.Fragment>
      <SEO title="" />
      <Layout>
        {BeforeFeaturedArticles[0].enabled && (
          <div
            sx={{
              margin: 'auto',
              maxWidth: '1060px',
              padding: '0 10px',
              marginBottom: '30px',
            }}
          >
            <MDXProvider components={{}}>
              <MDXRenderer>{BeforeFeaturedArticles[0].body}</MDXRenderer>
            </MDXProvider>
          </div>
        )}
        {pageContext.featuredArticles.length > 0 && (
          <React.Fragment>
            <div
              sx={{
                backgroundColor: 'heroBG',
                padding: '30px 0',
                marginBottom: '30px',
              }}
            >
              <div sx={{ margin: 'auto', maxWidth: '1260px' }}>
                <FeaturedPosts
                  basePath={pageContext.basePath}
                  data={pageContext.featuredArticles}
                />
              </div>
            </div>
            {BeforeArticlesList[0].enabled && (
              <div
                sx={{
                  margin: 'auto',
                  maxWidth: '1060px',
                  padding: '0 10px',
                  marginBottom: '30px',
                }}
              >
                <MDXProvider components={{}}>
                  <MDXRenderer>{BeforeArticlesList[0].body}</MDXRenderer>
                </MDXProvider>
              </div>
            )}
          </React.Fragment>
        )}

        <div sx={{ margin: 'auto', maxWidth: '1060px' }}>
          <div
            sx={{
              display: 'grid',
              gap: '30px',
              padding: '0 10px',
              gridTemplateColumns: ['1fr', '2fr 1fr'],
            }}
          >
            <ArticlePostList
              basePath={pageContext.basePath}
              data={pageContext.nonFeaturedArticles}
            />
            <div>
              <AboutAuthorWidget
                author={pageContext.featuredAuthor}
                sx={{ marginBottom: '30px' }}
              />
              <Sidebar />
            </div>
          </div>
        </div>
        {BeforeFooter[0].enabled && (
          <div
            sx={{
              margin: 'auto',
              maxWidth: '1060px',
              padding: '0 10px',
              marginTop: '30px',
            }}
          >
            <MDXProvider components={{}}>
              <MDXRenderer>{BeforeFooter[0].body}</MDXRenderer>
            </MDXProvider>
          </div>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Index;
