/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

interface Props {
  data: any;
  basePath: string;
}
const FeaturedPosts = ({ basePath, data }: Props) => {
  let gridTemplateAreasFeatured;
  let gridTemplateRowsFeatured;
  let gridTemplateColumnsFeatured;
  switch (data.length) {
    case 1:
      gridTemplateAreasFeatured = [
        `
      "featured_1 featured_1 featured_1"
      `,
        `
    "featured_1 featured_1 featured_1"
    `,
      ];
      gridTemplateRowsFeatured = ['225px', '360px'];
      gridTemplateColumnsFeatured = ['repeat(2, 1fr)', 'repeat(3, 1fr)'];

      break;
    case 2:
      gridTemplateAreasFeatured = [
        `
        "featured_1 featured_2"
        `,
        `
      "featured_1 featured_2"
      `,
      ];
      gridTemplateRowsFeatured = ['225px', '360px'];
      gridTemplateColumnsFeatured = ['repeat(2, 1fr)', 'repeat(2, 1fr)'];

      break;
    case 3:
      gridTemplateAreasFeatured = [
        `
        "featured_1 featured_1"
        "featured_2 featured_3"
        `,
        `
        "featured_1 featured_1"
        "featured_2 featured_3"
        `,
      ];
      gridTemplateRowsFeatured = ['225px 225px', '360px 225px'];
      gridTemplateColumnsFeatured = ['repeat(2, 1fr)', 'repeat(2, 1fr)'];
      break;
    case 4:
      gridTemplateAreasFeatured = [
        `
          "featured_1 featured_2"
          "featured_3 featured_4"
          `,
        `
          "featured_1 featured_2"
          "featured_3 featured_4"
          `,
      ];
      gridTemplateRowsFeatured = ['225px 225px', '360px 225px'];
      gridTemplateColumnsFeatured = ['repeat(2, 1fr)', 'repeat(2, 1fr)'];
      break;
    default:
      gridTemplateAreasFeatured = [
        `
      "featured_1 featured_1"
      "featured_2 featured_3"
      "featured_4 featured_5"
      `,
        `
    "featured_1 featured_1 featured_2"
    "featured_3 featured_4 featured_5"
    `,
      ];
      gridTemplateRowsFeatured = ['225px 225px 225px', '360px 225px'];
      gridTemplateColumnsFeatured = ['repeat(2, 1fr)', 'repeat(3, 1fr)'];
  }

  return (
    <React.Fragment>
      <div sx={{ padding: '0 10px' }}>
        <div
          sx={{
            display: ['grid', '-ms-grid'],
            gridTemplateColumns: gridTemplateColumnsFeatured,
            gridTemplateRows: gridTemplateRowsFeatured,
            boxSizing: 'border-box',
            gap: '2px',
            backgroundColor: 'heroBG',
            margin: '30px 0 30px 0',
            borderColor: 'background',
            gridTemplateAreas: gridTemplateAreasFeatured,
          }}
        >
          {data.map((article: any, index: number) => {
            const { id, title, featuredImage, slug } = article;
            return (
              <div
                key={`featued_${id}`}
                sx={{
                  gridArea: 'featured_' + (index + 1).toString(),
                  backgroundColor: '#ccc',
                  overflow: 'hidden',
                  position: 'relative',
                  transition: 'all 0.2s ease',
                  borderColor: 'lightBorder',
                }}
              >
                {featuredImage && (
                  <Link to={basePath + '/' + slug} title={title}>
                    <Img
                      fluid={featuredImage}
                      sx={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        ':hover': {
                          filter: 'grayscale(50%)',
                        },
                      }}
                    />
                  </Link>
                )}
                <h2 sx={{ margin: '0', color: 'primary' }}>
                  <Link
                    sx={{
                      position: 'absolute',
                      zIndex: '2',
                      bottom: '0',
                      left: '0',
                      backgroundColor: 'rgba(255, 255, 255, 0.78)',
                      padding: '15px 10px',
                      backdropFilter: 'blur(4px)',
                      width: '100%',
                      color: 'featuredLinkColor',
                    }}
                    to={basePath + '/' + slug}
                  >
                    {title}
                  </Link>
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeaturedPosts;
